import React, { Fragment, Suspense, useState } from "react";
import Preloader from "../elements/Preloader";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const BookNow = React.lazy(() => import("../components/BookNow"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterTwo = React.lazy(() => import("../components/FooterTwo"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const LaborForm = React.lazy(() => import("../components/LaborForm"));

const LaborQuote = () => {
  
  return (
      <>
        <Fragment>
          <Suspense fallback={<Preloader />}>
            {/* Search Popup */}
            <SearchPopup />

            {/* Navbar One */}
            <NavbarTwo />

            {/* Breadcrumb */}
            <Breadcrumb title={"LABOR QUOTE"} />

            {/* <TruckFilter /> */}
            {/* Breadcrumb */}
            <LaborForm />

            {/* Footer One */}
            <FooterTwo />

            {/* Footer Bottom One */}
            <FooterBottomOne />
          </Suspense>
        </Fragment>
      </>
  );
};

export default LaborQuote;
