import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const AboutTwo = React.lazy(() => import("../components/AboutTwo"));
const BannerTwo = React.lazy(() => import("../components/BannerTruck"))
const CounterTwo = React.lazy(() => import("../components/CounterTwo"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterTwo = React.lazy(() => import("../components/FooterTwo"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const PartnerTwo = React.lazy(() => import("../components/PartnerTwo"));
const RequestQuoteOne = React.lazy(() =>
  import("../components/RequestQuoteOne")
);
const ServiceTwo = React.lazy(() => import("../components/ServiceTwo"));
const TestimonialTwo = React.lazy(() => import("../components/TestimonialTwo"));
const WhyChooseUsTwo = React.lazy(() => import("../components/WhyChooseUsTwo"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const HomeTwo = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          <SearchPopup />

          <NavbarTwo />

          <BannerTwo />

          {/* <PartnerTwo />

          <TestimonialTwo />

          <AboutTwo />

          <CounterTwo />

          <ServiceTwo />

          <WhyChooseUsTwo /> */}

          {/* <RequestQuoteOne /> */}
          <FooterTwo />

          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default HomeTwo;
